img#game {
  width: auto;
  height: 550px;
  object-fit: contain;

  border-radius: 10px;
  padding: 0px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.question {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  flex-direction: column;
}
.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nextquestion {
  background-color: white;
  color: black;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 20px;
  padding: 5px 30px;
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
}

/* .options {
  margin: 20px;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */

/* .options {
  margin: auto;
  position: absolute;
  margin-top: 30px;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
} */

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 9px;
  border-radius: 5px;
  background: white;
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: white;
  cursor: pointer;
}
.results {
  align-items: center;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
}

.inputvalue {
  align-items: center;
  align-content: center;
  text-align: center;
  border-radius: 7px;
  font-size: large;
  padding: 6px 6px 6px 6px;
}
