.overview {
  text-align: center;
  align-content: center;
  margin-bottom: 5%;
  margin-top: 100px;
}

img#image {
  width: 90%;
  height: 250px;
  object-fit: cover;

  border-radius: 4px;
  padding: 5px;
  padding-right: 1px;
  display: block;
  margin-left: 2px;
}

.event {
  display: flex;
}

.conclusion_container {
}

.eventdescription {
  flex: 1;
}

.conclusion {
  flex: 1;
  display: inline-block;
  margin-top: 110px;
  margin-right: 20%;
}
.restartbutton {
  background-color: orange;
  color: white;
  font-size: 20px;
  padding: 10px 40px;
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
}

h4,
h3,
h2,
h1,
p {
  color: white;
}
