.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: none;
}

.thumb {
  -webkit-appearance: none;
  width: 80%;
  height: 2px;
  border-radius: 5px;
  background: white;
  outline: none;
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  margin-top: 40px;
  margin-bottom: 30px;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  left: 10%;
}
/* .slider__track {
  position: absolute;
  height: 50px;
  border-radius: 3px;
  z-index: -1;
  width: 50%;
} */
*/ .thumb--left {
  z-index: 3;
}

.thumb--right {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  margin-top: 0px;
  pointer-events: all;
  position: relative;
}

/* .restartbutton {
  background-color: orange;
  color: black;
  padding: 5px 30px;
  border-radius: 8px;
  margin: 10px 0px;
  cursor: pointer;
  width: 100px;
  height: 50px;
  font-family: "Poppins";
  font-size: 200px;
} */

.restartbutton1 {
  background-color: white;
  color: black;

  border-radius: 8px;

  cursor: pointer;
  width: 100px;

  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  justify-content: "center";
  align-items: center;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.restartbutton2 {
  background-color: orange;
  color: black;

  border-radius: 8px;

  cursor: pointer;
  width: 100px;

  font-family: "Poppins";
  font-size: 20px;
  font-weight: 600;
  text-align: center;

  padding-top: 6px;
  padding-bottom: 6px;
}

.font-link {
  font-family: "Poppins";
}
